import React from 'react';
import "./Post.css";



function Post({ username, message, timestamp, tag, onTagClick, image }) {
    const messageParagraphs = message.split('\n').filter(Boolean);
    const handleTagClick = () => {
        onTagClick(tag); // Call the function with the tag
    };

    return (
        <div className="post">
            <div className="post-header">
                <p className="post-username">{username}</p>
                <p className="post-timestamp">{timestamp.toString()}</p>
            </div>
            {image && <img src={image} alt="Post Image" className="post-image" />}
            <div className="post-content">

                {messageParagraphs.map((paragraph, index) => (
                    <div className="post-message" key={index}>
                        {paragraph}
                    </div>
                ))}
            </div>
            <button className="post-tag" onClick={handleTagClick}>{tag} (tag)</button>
        </div>
    );
}

export default Post;