import React, { useEffect, useState } from 'react';
import '../src/App.css';
//import { BrowserRouter, Route, Link } from "react-router-dom";
//import { Link } from "react-router-dom";
import githubIcon from "./images/github.svg";
import linkedinIcon from "./images/linkedin.svg";
import stackOverflowIcon from "./images/stackoverflow.svg";
import twitterIcon from "./images/twitter.svg";


function Footer() {
    const [lastPushed, setLastPushed] = useState('');

    useEffect(() => {
        const accessToken = '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'; // Replace with your GitHub personal access token
        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        // Make a request to the GitHub API to get the repository information with the access token
        fetch('https://api.github.com/repos/turacept/FullStack', { headers })
            .then(response => response.json())
            .then(data => {
                const lastPushedAt = new Date(data.pushed_at);
                const formattedDate = lastPushedAt.toLocaleDateString();
                setLastPushed(formattedDate);
            })
            .catch(error => console.error('Error:', error));
    }, []);


    return (
        <div className="footerPanel">
            <div className="socialLinks">
            <a href="https://github.com/turacept"><img src={githubIcon} alt="Hello"></img></a>
            <a href="https://www.linkedin.com/in/weston-forbes/"><img src={linkedinIcon} alt="Hello"></img></a>
            <a href="https://stackoverflow.com/users/20914623/weston"><img src={stackOverflowIcon} alt="Hello"></img></a>
            <a href="https://twitter.com/WestonFor"><img src={twitterIcon} alt="Hello"></img></a>
            </div>
            <p>Last Updated: {lastPushed}</p>
            <span id="last-update-date"></span>

        </div>

    );
}

export default Footer;