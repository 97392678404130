import '../App.css';
import React from 'react';
import img from '../images/city.jpg'
import platekLogo from '../images/PlatekLogo-1000x1000.png'
import star from '../images/star.webp'

function Home() {
    return (
    <div className="container">
                <div className="containerContent">
                    <div className="introBlurb">
                        <img src={platekLogo} style={{width: "15vh"}}></img>
                        <p>I'm Weston, an 18-year-old software enthusiast hailing from the vibrant Greater Toronto Area. <br></br><br></br>Embarking on my journey into the world of professional development, I'm gearing up for college to dive deeper into the realms of software development. </p>
                    </div>


                    <div className="projectsMinimized">
                        <h4>Projects</h4>
                        <div className="projectSingleMinimized">
                            <img src={star}></img>
                            <div className="textInfoSection">
                            <h5>600 Doors</h5>
                            <p>Roguelike Dungeon-Crawler</p>
                            </div>
                            <a href="/portfolio#doors" className="btn btn-secondary" role="button">View</a>
                        </div>
                        <div className="projectSingleMinimized">
                            <img src={star}></img>
                            <div className="textInfoSection">
                            <h5>GraviCube</h5>
                            <p>Mobile Gravity-Flipper</p>
                            </div>
                            <a href="/portfolio#gravi" className="btn btn-secondary" role="button">View</a>
                        </div>
                        <div className="projectSingleMinimized">
                            <img src={star}></img>
                            <div className="textInfoSection">
                            <h5>Weston's Pizzeria</h5>
                            <p>Restauraunt Simulation</p>
                            </div>
                            <a href="/portfolio#pizza" className="btn btn-secondary" role="button">View</a>
                        </div>
                    </div>

                    <div className="projectsMinimized">
                        <h4>Blog Posts</h4>
                        <div className="projectSingleMinimized">
                            <div className="textInfoSection">
                                <h5>Blog Post Name</h5>
                                <p>Blog Post Summary</p>
                            </div>
                            <a href="/blogs" className="btn btn-secondary" role="button">View</a>
                        </div>
                        <div className="projectSingleMinimized">
                            <div className="textInfoSection">
                                <h5>Blog Post Name</h5>
                                <p>Blog Post Summary</p>
                            </div>
                            <a href="/blogs" className="btn btn-secondary" role="button">View</a>
                        </div>
                        <div className="projectSingleMinimized">
                            <div className="textInfoSection">
                                <h5>Blog Post Name</h5>
                                <p>Blog Post Summary</p>
                            </div>
                            <a href="/blogs" className="btn btn-secondary" role="button">View</a>
                        </div>
                    </div>

                    <div className="spacerDiv"></div>

                </div>
    </div>

    );
}



export default Home;
