import "../Post.css";
import React, {useState} from 'react';
import Post from "../Post";


function Blogs() {
    const [selectedTag, setSelectedTag] = useState("");
    const [posts] = useState([
        {
        username: "My New Web Extension!",
        message: "Just got my web extension accepted onto the Chrome Web Store, so anyone can use my simple tool. \n" + "https://chrome.google.com/webstore/detail/burning-links/kjkelcdhgpficagihfenjfknmogfkllo?hl=en&authuser=0",
        timestamp: new Date(1696092217678),
        tag: "Web",
        },
        {
            username: "MMORPG-Style GUI",
            message: "Working on a MMO-themed player interface for fun, not sure if it will serve any particular purpose.",
            timestamp: new Date(1695951981821),
            tag: "Gamedev",
            image: "https://i.gyazo.com/5d2db92f571a3f429c42b2b2ad996e35.png",
        },
        {
            username: "2 Basic Ways I Keep My C# Pretty",
            message: "There's a couple ways I like to keep my C# code more readable, but theres 2 specific conventions that I tend to use EXTREMELY often.\n" +
                "--------(Number 1)--------\n" +
                "Instead of wrapping an entire method's primary contents with if-loops, and continually nesting the code until it's indented to the high heavens. " +
            "Try escaping the method early with a return; at the beginning, this will save the code's readability tenfold especially in scenarios where many requirements are needed.\n" +
                "--------(Number 2)--------\n" +
                "The second one, is instead of checking for direct comparisons between objects in those if statements, store a local boolean and use comparative operators within the assignment as opposed to writing it in the if brackets, " +
            " for example : \n bool DoIHaveOver100Dollars = (money > 100) ? true : false; \n Now, instead of using an if statement, or comparing values themselves, you've added another layer of readability, as now the if statement will simply be if(DoIHaveOver100Dollars) instead of if(money > 100) \n both are similarly readable, but in more complicated cases this becomes a lot more useful.",
            timestamp: new Date(1695668013393),
            tag: "Code",
            //image: "https://cdn.gsmarena.com/pics/10/12/10-vg-2010/main.jpg",
        },
        {
            username: "AI Art...",
            message: "I keep seeing moronic posts about how AI art is stupid, and effortless; and therefore should not be considered art. Bla Bla Bla.  " +
                "These people are almost always seething artists trying to find some meaning in their art.\n" +
                "Instead of embracing these new technologies, you'll find countless artists dissing the art form, and denouncing it as simply a effortless clone of other's work." +
                "When you take that into perspective of; art as a whole, the argument begins to fall apart a bit.\n" +
                "Everything in this world is inspired by something else, " +
                "If AI art would be considered 'stealing' by learning from other art pieces, " +
                "then you could essentially consider most art to be 'stolen', as most art is inspired by other artists' styles/conventions/mediums/etc. \n" +
                "Does the skill of the artist therefore become null due to the factor of inspiration? " +
                "What I really think their main issue is, is that their field will become one of the first to become obsolete in a professional capacity, as AI can already produce works much greater than most artists could ever dream of making (and can produce an infinite amount in seconds, not hours, days or weeks).",
            timestamp: new Date(1695580182600),
            tag: "Rant",
            //image: "https://cdn.domestika.org/c_fill,dpr_auto,f_auto,h_630,q_auto,w_1200/v1647949550/blog-post-open-graph-covers/000/010/352/10352-original.jpg?1647949550",
        },
        {
            username: "New Color-Scheme/Design",
            message: "Got rid of the contact section, added a neat footer; this will serve as the 'contact' page alternatively, which will ultimately clean up the website due to there being less wasted space.",
            timestamp: new Date(1694972051314),
            tag: "Updates",

        },
        {
            username: "First Post!",
            message: "So this is my first post I guess, currently I'm using a method for the blogs I would prefer not to use; which is simply a JavaScript tuple storing all the data. I plan to change this instead to a SQL-based solution, using a .NET Core Web API & Microsoft SQL Servers.",
            timestamp: new Date(1694794698324),
            tag: "Updates",
        },
    ]);


    const handleTagClick = (tag) => {
        setSelectedTag((prevTag) => (prevTag === tag ? "" : tag));
    };

    const filteredPosts = selectedTag
        ? posts.filter((post) => post.tag === selectedTag)
        : posts;


    return (
        <div className="blogContainer">
                    <div className="posts">
                        {filteredPosts.map((post, index) => (
                            <Post
                                key={index}
                                username={post.username}
                                message={post.message}
                                timestamp={post.timestamp}
                                tag={post.tag}
                                image={post.image} // Include the image property
                                onTagClick={handleTagClick}
                            />
                        ))}
                    </div>
        </div>
    );
}

export default Blogs;
