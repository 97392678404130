//import logo from './logo.svg';
import '../App.css';
//import logo from '../puffin.png';
import React from 'react';


function NoPage() {
    return (
        <div className="container">
            <div className="slides slide1" id="slide1">
                <div className="containerContent">
                    <h1>No Page Found!</h1>
                </div>
            </div>
        </div>
    );
}

export default NoPage;
