import React from "react";
//import { BrowserRouter, Route, Link } from "react-router-dom";
//import { Link } from "react-router-dom";
import App from "./App.css";

import homeIcon from './images/headerIcons/home.png';
import aboutIcon from './images/headerIcons/information-button.png';
import blogIcon from './images/headerIcons/blog.png';
import projectsIcon from './images/headerIcons/verified.png';

function Navbar() {
  return (
      <div className="header" id="myHeader">
          <a href="/" className="btn btn-primary btn-lg active" role="button" style={{backgroundColor: "black", borderColor:"white"}} aria-pressed="true">
              <img src={homeIcon} style={{width: "2vh", marginBottom: "5px"}}></img> Home</a>
          <a href="portfolio" className="btn btn-primary btn-lg active" role="button" style={{backgroundColor: "black", borderColor:"white"}} aria-pressed="true">
              <img src={projectsIcon} style={{width: "2vh", marginBottom: "5px"}}></img> Portfolio</a>
          <a href="blogs" className="btn btn-primary btn-lg active" role="button" style={{backgroundColor: "black", borderColor:"white"}} aria-pressed="true">
              <img src={blogIcon} style={{width: "2vh", marginBottom: "5px"}}></img> Blog</a>

      </div>
  );
}

export default Navbar;