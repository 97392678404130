import '../App.css';
import React from 'react';
import cSharp from '../images/csharp.png'
import javaIco from '../images/javaIcon.png'
import dartIco from '../images/dartIcon.webp'
import jsIco from '../images/JSIcon.png'
import pythonIco from '../images/pythonLogo.png'
import luaIco from '../images/luaLogo.svg'
import htmlIco from '../images/htmlIco.webp'
import cssIco from '../images/CssIco.webp'
import unityIco from '../images/unity.png'
import robloxIco from '../images/roblox.png'

import sixhundreddContent from '../images/portfolioImages/600dcontent.jpg'
import sixhundreddContentTwo from '../images/portfolioImages/600dlevelcontent.png'

import robloxContent from '../images/portfolioImages/robloxTakingOrder.jpg'
import robloxContentTwo from '../images/portfolioImages/robloxOrderLine.jpg'

import tdsContent from '../images/portfolioImages/tdsContent.jpg'
import tdsContentTwo from '../images/portfolioImages/tdsDrivingContent.jpg'

import graviCubeOne from '../images/portfolioImages/graviCubeMain.png'
import graviCubeTwo from '../images/portfolioImages/GraviCubeLevelEdit.png'

import modelOne from '../images/portfolioImages/ak47Content.png'
import modelTwo from '../images/portfolioImages/pistolContent.png'
import modelThree from '../images/portfolioImages/shipContent.jpg'

function Portfolio() {
    return (
        <div className="container">
            <div className="portfolioSlide" id="slide1">
                <div className="portfolioHeader">
                <h1> Weston Forbes </h1>
                <h5> Software Engineer </h5>
                </div>

                <div className="ContentSection">
                    <div className="portfolioCentreContent">
                    <p> Throughout my time developing/programming (since approx 2013), I've learned a plethora of different skills.</p>
                    <p> I've done projects ranging from Websites, Software, Video Games, and mods/addons for Video Games.</p>
                    <p> From a young age I've fostered a love for everything computer-related, and in doing so, have made it extremely easy--</p>
                    <p> to captivate my focus & attention on personal & professional projects in the realm of Software/Web/Game Development.</p>
                    </div>
                </div>

                <div className="LanguageIcons">
                    <img src={cSharp}></img>
                    <img src={javaIco}></img>
                    <img src={pythonIco}></img>
                    <img src={luaIco}></img>
                    <img src={dartIco}></img>
                    <img src={jsIco}></img>
                    <img src={htmlIco}></img>
                    <img src={cssIco}></img>
                </div>

                <div className="ContentSection" id="doors">
                    <h5>600 Doors</h5>
                    <img src={unityIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <img src={cSharp} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <br></br>

                    <div className="portfolioCentreContent">
                    <img src={sixhundreddContent} style={{width: "50%"}}></img>
                    <p> A rogue-like dungeon crawler inspired by the likes of Darkest Dungeon, and Enter the Spire. Make your way through the dungeon & attempt to survive the various cautions. Targeting Desktop & Mobile. </p>
                    </div>

                        <br></br>
                    <a href="https://www.youtube.com/watch?v=Cr9tLWKnjD8" className="contentButton" role="button" aria-pressed="true">View Video</a>
                    <a href="" className="contentButton" role="button" aria-pressed="true">Purchase</a>
            </div>
                <div className="ContentSection" id="gravi">
                    <h5>GraviCube</h5>
                    <img src={unityIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <img src={cSharp} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <br></br>

                    <div className="portfolioCentreContent">
                    <img src={graviCubeOne} style={{width: "50%"}}></img>
                    <p>Mobile App targeting IOS & Android </p>
                    </div>

                        <br></br>
                    <a href="" className="contentButton" role="button" aria-pressed="true">View Video</a>
                    <a href="" className="contentButton" role="button" aria-pressed="true">Download</a>
                </div>
            <div className="ContentSection" id="pizza">
                <h5>Weston's Pizzeria </h5>
                <img src={robloxIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                <img src={luaIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                <br></br>

                <div className="portfolioCentreContent">
                    <img src={robloxContent} style={{width: "50%"}}></img>
                    <p>A Papa's Pizzeria-inspired pizza restaurant management/simulation game, targeting Desktop, Mobile & Console.</p>
                </div>

                <br></br>
                <a href="https://youtu.be/Lts9BBH_2M0" className="contentButton" role="button" aria-pressed="true">View Video</a>
                <a href="" className="contentButton" role="button" aria-pressed="true">Play</a>

                </div>
                <div className="ContentSection" id="tds">
                    <h5>Top Down Shooter</h5>
                    <img src={unityIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <img src={cSharp} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <br></br>
                    <div className="portfolioCentreContent">
                    <img src={tdsContentTwo} style={{width: "50%"}}></img>
                    <p>Desktop Game, targeting Windows & MacOS; in which you're a gangster trying to fight your way through the ranks of the gangs of the city, and make it out on top alive. (Inspired by GTA 1 & Hotline Miami)</p>
                    </div>
                    <br></br>
                    <a href="https://www.youtube.com/watch?v=ffsEAZvCmtk" className="contentButton" role="button" aria-pressed="true">View Video</a>
                    <a href="" className="contentButton" role="button" aria-pressed="true">Play</a>
                </div>
                <div className="ContentSection" id="burning">
                    <h5>Burning Links</h5>
                    <img src={htmlIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <img src={cssIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <img src={jsIco} style={{width: "6vh", height:"6vh", marginLeft: "1%", marginBottom: "3vh", border: "solid 2px black", borderRadius:"15px", padding:"1vh", backgroundColor:"gray"}}></img>
                    <br></br>

                    <div className="portfolioCentreContent">
                    <p>A simple book-marking tool but with expiry dates for the links, things you don't want to clog your real bookmarks up with yet also don't mind if the link disappears after the chosen expiry amount of time.</p>
                    </div>
                        <br></br>
                    <a href="" className="contentButton" role="button" aria-pressed="true">Download</a>
                </div>



            </div>




        </div>

    );
}

export default Portfolio;
